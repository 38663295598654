@bo-blue: #2e3192;

body {
  font-family: Exo, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}

form, .btn {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.wrapper {
  margin: 0 auto;
  padding-bottom: 30px;
  max-width: 2340px;
  background: url('../img/background-line.png') repeat-x 0 90px,
  url('../img/background.jpg') no-repeat center 95px,
  #fff;
}

h1 {
  font-size: 46px;
  font-weight: bold;
}

header {
  position: relative;
}

.top-logo {
  margin-top: 8px;
  display: inline-block;
  border: 5px solid #fff;
  border-top-width: 0;
  background: #fff;
  border-radius: 16px;
}

nav {
  position: absolute;
  top: 40px;
  left: 380px;
  font-size: 20px;
}
nav > ul > li {
  position: relative;
  display: inline-block;
}

nav > ul > li + li {
  margin-left: 25px;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav a {
  color: #000;
}

nav a.active {
  font-weight: bold;
  text-decoration: underline;
}

nav a:hover {
  color: #000;
}

/*Submenu*/
nav ul ul {
  display: none;
}

nav li ul {
  position: absolute;
  padding-top: 22px;
}

nav li ul:before {
  position: absolute;
  top: 12px;
  left: 10px;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid @bo-blue;
}

nav li ul li {
  background: @bo-blue;
  font-size: 15px;
}

nav li ul li a {
  display: block;
  padding: 4px 15px;
  color: #fff;
}

nav li ul li:first-child a {
  padding-top: 20px;
}

nav li ul li:last-child a {
  padding-bottom: 20px;
}

nav li ul li a:hover {
  text-decoration: none;
}

nav li:hover ul {
  display: block;
}

.content {
  background: url('../img/content-backgound.png') repeat-x 0 396px;
  padding-bottom: 40px;
}

.container {
  width: 970px !important;
}

.frontpage-image-container {
  margin-top: 50px;
  height: 445px;
  border: 5px solid #fff;
}

.frontpage-image-container a {
  position: relative;
  padding-top: 275px;
  float: left;
  text-align: center;
  color: #000;
  font-size: 38px;
  text-decoration: none;

  &:hover span {
    background: rgba(255,255,255,.85);
  }

  span {
    width: 100%;
    display: inline-block;
    background: rgba(255,255,255,.7);
  }
}

.frontpage-middle-image:before,
.frontpage-middle-image:after {
  position: absolute;
  top: 0;
  left: -2px;
  content: '';
  width: 5px;
  height: 435px;
  background: #fff;
  z-index: 10;
}

.frontpage-middle-image:after {
  left: auto;
  right: -2px;
}

.frontpage-middle-virtual {
  width: 310px;
  height: 435px;
  background-image: url('../img/frontpage/virtual.png');
}

.frontpage-middle-physical {
  width: 310px;
  height: 435px;
  background-image: url('../img/frontpage/physical.png');
}

.frontpage-middle-network {
  width: 310px;
  height: 435px;
  background-image: url('../img/frontpage/network.png');
}

.department-description {
  position: relative;
  width: 500px;
  height: 155px;
  margin-top: 20px;
}

.department-description-right {
  width: auto;
  margin-left: 30px;

  .department-button {
    top: 250px;
  }
}

.department-header {
  margin-bottom: -5px;
  font-size: 36px;
  font-weight: bold;
}

.department-text {
  margin-top: 10px;
  width: 290px;
}

a.department-button,
.department-button {
  position: absolute;
  top: 75px;
  right: 0;
  display: inline-block;
  background: url('../img/button.png');
  width: 183px;
  height: 49px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 49px;
  font-size: 24px;
  text-decoration: none;
}

.company-description {
  padding-top: 30px;
  padding-bottom: 50px;
  text-align: center;
  background: #000;
}

.company-description-header {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: bold;
  color: #fff;
}

.company-description-text {
  margin: 0 auto;
  width: 600px;
  color: #555;
}

footer {
  margin-top: 40px;
}

.footer-list {
  padding-left: 30px;
  position: relative;
  list-style: none;
  font-size: 15px;
  color: #444;
}

.footer-list:before {
  position: absolute;
  top: 0;
  left: 5px;
  content: '>';
  font-weight: bold;
}

.footer-list a {
  color: #999;
}

.department-name, .department-phone {
  font-size: 24px;
  font-weight: bold;
}

.department-phone {
  color: @bo-blue;
}

.orange {
  color: @bo-blue
}

.border-right {
  border-right: 1px solid #bfbfbf;
}

.border-left {
  border-left: 1px solid #bfbfbf;
}

.border-bottom {
  border-bottom: 1px solid #bfbfbf;
}

.content-box {
  min-height: 400px;
  margin-top: 40px;
  background: #fff;
}

.content-page {
  padding: 20px;
  border-radius: 3px;
}

.product-header, .category-header {
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 46px;
  font-weight: 800;
  text-align: center;
}

.categories {
  background: @bo-blue;
  text-align: center;
  font-size: 18px;
  line-height: 34px;
}
.categories a {
  color: #000;
}

.categories a:hover {
  text-decoration: none;
}

.categories li {
  padding: 0 10px;
  display: inline-block;
}

.products {
  padding: 20px;
}

.product {
  margin-left: 25px;
  display: inline-block;
  width: 280px;
}
.product:hover {
  text-decoration: none;
}

.product:first-child {
  margin: 0;
}

.product-row {
  margin-bottom: 20px;
}

.product-image {
  width: 280px;
  height: 200px;
}

.product-text {
  display: block;
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
}

.product-box {
  padding: 20px;
}

.reference-table h4 {
  font-weight: bold;
}

.reference-table > tbody > tr > td {
  vertical-align: middle;
}

.reference-table > tbody > tr > .logo-cell {
  padding: 10px 30px;
  text-align: center;
}

/* ADMIN */

.admin {
  padding: 20px;
}

.admin h1 {
  margin-top: 0;
  padding-bottom: 20px;
  font-weight: 800;
}

.btn-icon {
  padding-left: 8px;
  padding-right: 8px;
  color: #333;
  cursor: pointer;
}
.btn-icon:hover {
  color: #333;
}